import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface ICartMethodChangeProps{
    className: string;
    HeaderText: string;
    BodyText: string;
    modalOpen: boolean | undefined;
    onClose(): void;
}

export const CartMethodChangeDialog = (dialogProps: ICartMethodChangeProps) => {
    const {

        className,
        BodyText,
        HeaderText,
        modalOpen

    } = dialogProps;

    const close: () => void = () => {
        dialogProps.onClose();
    };

    return (
        <Modal isOpen={modalOpen} className={`${className}`} toggle={close}>
            <ModalHeader className={`${className}__dialog__header`} toggle={close}>
                <span>{HeaderText}</span>
            </ModalHeader>
            <ModalBody className={`${className}__dialog__body`}>
            {/* <p>Your cart contains items with a mix of &apos;Delivery&lsquo; and &apos;Store Pick-up&lsquo; delivery options. Please make sure all items are either &apos;Delivery&lsquo; or &apos;Store Pick-up&lsquo; before continuing to checkout.</p> */}
            <div className={`${className}__dialog__content`} style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html:BodyText}} />
            </ModalBody>
            <ModalFooter className={`${className}__dialog__footer`}>
                <Button className={`${className}__dialog__continue-shopping`}  onClick={close}>Ok</Button>
                {/* <Button className={`${className}__dialog__continue-shopping`} onClick={close}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    );
};

